import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UseWalletProvider } from 'use-wallet'
import useChainIds from '../../../hooks/useChainIds'
import useStorefront from '../../../hooks/useStorefront'
import useUser from '../../../hooks/useUser'
import { getTokenCookie } from '../../../utils/cookie'

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter()
  const storefront = useStorefront()
  const { t } = useTranslation()
  const chainIds = useChainIds()
  const me = useUser()

  const rpcConfig = useMemo(() => {
    const blockchains =
      storefront?.blockchains.map(
        (storefrontBlockchains) => storefrontBlockchains.blockchainContract.blockchain
      ) ?? []

    const config = {}
    blockchains.forEach((blockchain) => {
      config[blockchain.chainId] = blockchain.rpcUrl
    })

    return config
  }, [storefront])

  const handleFocus = useCallback(() => {
    const currentToken = getTokenCookie()
    if ((me && !currentToken) || (!me && currentToken)) {
      router.reload()
    }
  }, [me, router])

  useEffect(() => {
    window.addEventListener('focus', handleFocus)
    return () => {
      window.removeEventListener('focus', handleFocus)
    }
  }, [handleFocus])

  return (
    <>
      <UseWalletProvider
        connectors={{
          injected: { chainIds },
          walletconnect: {
            rpc: rpcConfig,
          },
        }}
      >
        <Head>
          <title>{t('title')}</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        {children}
      </UseWalletProvider>
    </>
  )
}

export default Layout
