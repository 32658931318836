import classNames from 'classnames'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

const NavbarItem: React.FC<React.PropsWithChildren<LinkProps>> = (props) => {
  const router = useRouter()

  const { href } = props
  const isActive = router.asPath === href || router.asPath.startsWith(href.toString())

  return (
    <Link {...props}>
      <a
        className={classNames(
          'font-nav',
          'transition-colors',
          'hover:text-black',
          'text-center',
          'whitespace-nowrap',
          'text-xs',
          {
            'font-black text-indigo-1': isActive,
            'text-indigo-1': !isActive,
          }
        )}
      >
        {props.children}
      </a>
    </Link>
  )
}

export default NavbarItem
