import { APILocaleChoices } from '@betablocks/shared/lib/api/users'
import { Menu, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import React from 'react'
import ChevronDownIcon from '../../../assets/icons/ChevronDownIcon'
import LangIcon from '../../../assets/icons/LangIcon'
import useApi from '../../../hooks/useApi'
import useUser from '../../../hooks/useUser'

const LanguagePicker: React.FC = () => {
  const router = useRouter()
  const user = useUser()
  const api = useApi()

  const handleLocaleChange = async (l: string): Promise<void> => {
    if (user) {
      try {
        await api.users.updateMe({ locale: l as APILocaleChoices })
      } catch (e) {
        return
      }
    }
    router.push(router.asPath, router.asPath, { locale: l })
  }

  return (
    <span className="relative inline-block">
      <Menu>
        <Menu.Button className="btn-plain text-sm font-normal px-4 py-2 uppercase flex flex-row items-center">
          <LangIcon className="mr-2" />
          {router.locale || router.defaultLocale}
          <ChevronDownIcon className="ml-2" />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="bg-white border border-gray-light absolute right-0 origin-top-right z-[10000]">
            {router.locales.map((l) => (
              <Menu.Item key={l} as="div" onClick={async () => await handleLocaleChange(l)}>
                <a className="block font-display text-sm text-black uppercase px-4 py-2 whitespace-nowrap cursor-pointer hover:bg-bg-2">
                  {`${new Intl.DisplayNames(l, { type: 'language' }).of(l)} (${l})`}
                </a>
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </span>
  )
}

export default LanguagePicker
