import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BetaBlocksLogo from '../../../assets/icons/BetaBlocksLogo'
import MenuCloseIcon from '../../../assets/images/menu-toggle-close.svg'
import MenuOpenIcon from '../../../assets/images/menu-toggle-open.svg'
import useStorefront from '../../../hooks/useStorefront'
import Button from '../../atoms/Button'
import NavbarItem from '../../atoms/NavbarItem'
import BannerIfTestnet from '../BannerIfTestnet'
import LanguagePicker from '../LanguagePicker'

const Navbar: React.FC<
  React.PropsWithChildren<{
    /** Whether or not to show the hamburger menu on mobile. */
    mobileHamburgerShow?: boolean | null

    /** Toggle the hamburger menu on mobile. */
    onMobileHamburgerToggle?: () => void

    subheader?: string | React.ReactNode
  }>
> = ({ children, mobileHamburgerShow = null, onMobileHamburgerToggle, subheader }) => {
  const { t } = useTranslation(['navbar', 'common'])
  const router = useRouter()
  const storefront = useStorefront()

  return (
    <nav className="relative grid grid-cols-2 lg:grid-cols-3 gap-10 bg-white h-16 lg:pl-6 pr-6 border-b">
      <div className="place-self-start self-center h-full flex items-center">
        {mobileHamburgerShow !== null ? (
          <Button
            variant="textBlack"
            className="block lg:hidden shrink-0"
            onClick={onMobileHamburgerToggle}
          >
            <Image
              src={mobileHamburgerShow ? MenuCloseIcon : MenuOpenIcon}
              width={20}
              height={20}
              alt=""
            />
          </Button>
        ) : null}

        <Link href="/">
          <a
            className={classNames('hidden', 'md:block', 'shrink-0', {
              // 'hidden lg:inline': !!subheader,
            })}
          >
            <BetaBlocksLogo />
          </a>
        </Link>

        {subheader ? (
          <div className="hidden md:block md:pl-1.5 md:border-l md:border-gray-300 md:ml-1.5 text-md lg:text-sm font-navbarSubheading select-none whitespace-nowrap">
            {subheader}
          </div>
        ) : null}
      </div>

      {storefront ? (
        <div className="hidden place-self-center self-center lg:flex gap-9">
          <NavbarItem href={`/storefronts/${storefront.id}/pages`}>{t('menu.pages')}</NavbarItem>
          <NavbarItem href={`/storefronts/${storefront.id}/digital-offerings`}>
            {t('menu.digitalOfferings')}
          </NavbarItem>
          <NavbarItem href={`/storefronts/${storefront.id}/reports`}>
            {t('menu.reports')}
          </NavbarItem>
          <NavbarItem href={`/storefronts/${storefront.id}/access`}>{t('menu.access')}</NavbarItem>
          <NavbarItem href={`/storefronts/${storefront.id}/settings`}>
            {t('menu.settings')}
          </NavbarItem>
        </div>
      ) : (
        <div className="hidden place-self-center self-center lg:flex gap-9" />
      )}
      <div className="place-self-end self-center flex flex-row">
        <div
          className={classNames(
            'place-self-end self-center lg:block',
            router.pathname === '/login' ? 'block' : 'hidden'
          )}
        >
          <LanguagePicker />
        </div>
        {children}
      </div>

      <div className="absolute -bottom-0 z-[1000]">
        <BannerIfTestnet />
      </div>
    </nav>
  )
}

export default Navbar
