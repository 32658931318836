import classNames from 'classnames'
import Link, { LinkProps } from 'next/link'
import React, { ReactNode } from 'react'

type Props = React.ButtonHTMLAttributes<HTMLElement> & {
  outline?: boolean
  rounded?: boolean
  variant?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'primaryBlue'
    | 'lightBlue'
    | 'textBlue'
    | 'textLightBlue'
    | 'textIndigoBlue'
    | 'harborBlue'
    | 'primaryGreen'
    | 'primaryGray'
    | 'primaryAccent'
    | 'blueGray8'
    | 'secondaryGray'
    | 'capsBlack'
    | 'capsBlue'
    | 'default'
    | 'textBlack'
    | 'textViolet'
    | 'capsViolet'
    | 'textPurple'
    | 'textWhite'
    | 'transBlue'
  box?: boolean
  target?: HTMLAnchorElement['target']
  href?: LinkProps['href']
  icon?: ReactNode
  iconAlign?: 'left' | 'right'
  type?: 'button' | 'submit' | 'reset'
  align?: 'left' | 'right' | 'both'
  caps?: boolean
  thinPadding?: boolean
  locale?: 'en' | 'pt' | false
}

const Button: React.FC<React.PropsWithChildren<Props>> = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      className: extraClassName,
      outline,
      rounded,
      variant,
      onClick,
      box,
      href,
      target,
      icon,
      iconAlign = 'right',
      type = 'button',
      align,
      disabled,
      caps,
      thinPadding,
      locale,
      ...rest
    },
    ref
  ) => {
    const size = box ? ['h-full w-full max-w-48'] : []

    const className = classNames(
      { rounded },
      [
        'active:scale-90',
        'active:transform',
        'flex',
        'font-bold',
        'h-9',
        'hover:ring-opacity-50',
        'inline-block',
        'items-center',
        'justify-center',
        'leading-0',
        'text-center',
        'text-xs',
        'tracking-wider',
        'transition-all',
        'uppercase',
        'whitespace-nowrap',
        ...size,
      ],

      { '!uppercase': caps },

      variant === 'primary' && !outline && `bg-black hover:ring hover:ring-black text-white`,
      variant === 'primary' &&
        outline &&
        `bg-transparent hover:ring hover:ring-black border border-black hover:border-transparent text-black`,

      variant === 'transBlue' &&
        outline &&
        `bg-accent opacity-50 hover:opacity-100 border-2 border-accent text-white hover:text-white `,

      variant === 'primaryBlue' &&
        !outline &&
        `bg-gradient-to-r from-gradient-end to-gradient-start hover:ring-2 hover:ring-gradient-start text-white`,
      variant === 'primaryBlue' &&
        outline &&
        `bg-white hover:bg-accent hover:text-white border border-accent text-accent`,

      variant === 'danger' && !outline && `bg-red-600 hover:ring hover:ring-red-600 text-white`,
      variant === 'danger' &&
        outline &&
        `bg-white hover:bg-red-600 hover:text-white border border-red-600 text-red-600`,

      variant === 'textWhite' &&
        !outline &&
        `bg-bb-1 text-white hover:underline hover:text-black !capitalize`,
      variant === 'textWhite' &&
        outline &&
        `bg-transparent border border-accent hover:border-black text-accent hover:text-black !capitalize`,

      variant === 'textBlue' &&
        !outline &&
        `text-accent hover:underline hover:text-black !capitalize`,
      variant === 'textBlue' &&
        outline &&
        `bg-white border border-accent text-accent hover:underline hover:text-black hover:border-black !capitalize`,

      variant === 'textIndigoBlue' && !outline && `text-accent hover:underline capitalize`,
      variant === 'textIndigoBlue' &&
        outline &&
        `bg-white border border-accent text-accent hover:underline capitalize`,

      variant === 'textLightBlue' &&
        !outline &&
        `text-bb-light hover:underline hover:text-black capitalize`,
      variant === 'textLightBlue' &&
        outline &&
        `bg-white border border-bb-light text-bb-light hover:underline hover:text-black hover:border-black capitalize`,

      variant === 'lightBlue' && !outline && `bg-bb-1 hover:ring-2 hover:ring-bb-1 text-white`,
      variant === 'lightBlue' &&
        outline &&
        `bg-bb-1 hover:ring-2 hover:text-white border border-bb-1 text-white`,

      variant === 'harborBlue' && !outline && `bg-bb-2 hover:ring-2 hover:ring-bb-2 text-indigo-1`,
      variant === 'harborBlue' &&
        outline &&
        `bg-white hover:bg-bb-2 hover:text-white border border-bb-2 text-indigo-1`,

      variant === 'textBlack' && `hover:underline`,

      variant === 'textViolet' && `text-[#9314F5] hover:underline hover:text-black !capitalize`,
      variant === 'textPurple' &&
        !outline &&
        `text-gradient-end hover:underline hover:text-black capitalize`,
      variant === 'primaryGreen' &&
        !outline &&
        `bg-green-1 hover:ring hover:ring-green-1 text-white`,
      variant === 'primaryGreen' &&
        outline &&
        `hover:border-green-2 border border-green-1 text-white`,

      variant === 'primaryGray' &&
        !outline &&
        `bg-gray-700 hover:ring hover:ring-gray-700 text-white`,
      variant === 'primaryGray' &&
        outline &&
        `bg-gray-700 hover:bg-black hover:opacity-100 border border-opacity-50 hover:border-0 text-white`,

      variant === 'primaryAccent' &&
        !outline &&
        `bg-[#DFEBF2] hover:ring-2 hover:ring-accent text-accent`,
      variant === 'primaryAccent' &&
        outline &&
        `bg-[#DFEBF2] hover:ring hover:ring-accent border border-accent hover:border-transparent text-accent`,

      variant === 'blueGray8' && !outline && `bg-bg-8 hover:ring hover:ring-bg-8 text-white`,
      variant === 'blueGray8' &&
        outline &&
        `bg-bg-8 hover:bg-black hover:opacity-100 border border-opacity-50 hover:border-0 text-white`,

      variant === 'secondaryGray' && !outline && `text-gray-700`,
      variant === 'secondaryGray' &&
        outline &&
        `bg-black hover:bg-gray-700 border border-opacity-50 text-white`,

      variant === 'capsBlack' && !outline && ` text-black hover:underline uppercase`,

      variant === 'capsBlue' && !outline && `text-bb-0 hover:underline hover:text-black uppercase`,
      variant === 'capsBlue' &&
        outline &&
        `text-bb-0 bg-[#DFEBF2] hover:underline hover:text-black uppercase`,

      variant === 'default' && !outline && `bg-bb-2 hover:ring hover:ring-bb-2 text-indigo-1`,
      variant === 'default' &&
        outline &&
        `bg-white hover:bg-bb-2 border border-bb-2 text-indigo-1 hover:text-indigo-1`,

      variant === 'capsViolet' && `text-[#9314F5] hover:underline hover:text-black !uppercase`,

      align === 'left' || align === 'both' ? 'pl-0' : thinPadding ? 'pl-4' : 'pl-6',
      align === 'right' || align === 'both' ? 'pr-0' : thinPadding ? 'pr-4' : 'pr-6',
      disabled ? 'opacity-25 pointer-events-none' : '',

      extraClassName
    )

    if (href) {
      return (
        <Link href={disabled ? '#' : href} locale={locale}>
          <a
            aria-disabled={disabled}
            ref={ref}
            target={target}
            className={className}
            onClick={onClick}
            onKeyPress={(e) => {
              onClick(e as any) /* eslint-disable-line @typescript-eslint/no-explicit-any */
            }}
            role="button"
            tabIndex={0}
          >
            {icon && iconAlign === 'left' && <span className="pr-2">{icon}</span>}
            {children}
            {icon && iconAlign === 'right' && <span className="pl-2">{icon}</span>}
          </a>
        </Link>
      )
    }

    return (
      <button className={className} onClick={onClick} type={type} disabled={disabled} {...rest}>
        {icon && iconAlign === 'left' && (
          <span className={children ? 'pr-2' : undefined}>{icon}</span>
        )}
        {children}
        {icon && iconAlign === 'right' && (
          <span className={children ? 'pl-2' : undefined}>{icon}</span>
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'
export default Button
