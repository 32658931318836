import { useMemo, useRef } from 'react'
import { useAsync } from 'react-use'
import useApi from './useApi'

const useChainIds = (): number[] => {
  const defaultChainIds = useRef([1, 137, 3, 5, 80001, 1337])
  const api = useApi()

  const { value } = useAsync(async () => {
    try {
      return await api.storefronts.getAvailableContracts()
    } catch {
      return []
    }
  }, [api])

  const _blockchains = useMemo(
    () => value?.map((contract) => contract.blockchain.chainId) ?? defaultChainIds.current,
    [value]
  )

  return _blockchains
}

export default useChainIds
