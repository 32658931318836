const ChevronDownIcon: React.FC<{ className?: string; fill?: string }> = ({ className, fill }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.628418 1.44672L1.31933 0.757812L4.94659 4.42279L8.57386 0.757812L9.26477 1.44672L4.94659 5.80978L0.628418 1.44672Z"
      fill={fill || '#272D71'}
    />
  </svg>
)
export default ChevronDownIcon
