import classNames from 'classnames'
import dynamic from 'next/dynamic'
import React, { useId } from 'react'
import TooltipIcon from '../../../assets/images/branding-setup/tooltip/TooltipIcon'

const ReactTooltip = dynamic(() => import('./shim'), {
  ssr: false,
})

type Props = {
  id?: string
  text?: string
  hoverText: string
  content?: React.ReactNode
  icon?: React.ReactNode | false
  textStyle?: string
  className?: string
  breakAll?: boolean
  width?: string
  currentColor?: boolean
}

const Tooltip: React.FC<Props> = ({
  id,
  text,
  hoverText,
  icon,
  textStyle,
  className,
  breakAll,
  content,
  currentColor,
}) => {
  const _id = useId()
  const tooltipId = id ?? _id

  return (
    <div className={classNames('flex flex-row cursor-pointer', className)}>
      <div
        data-tip
        data-for={tooltipId}
        className={classNames(
          'whitespace-nowrap',
          'overflow-hidden',
          'text-ellipsis',
          'flex align-middle items-center justify-center'
        )}
      >
        {content || (
          <div
            className={classNames(
              'relative',
              'items-center',
              'flex',
              'flex-row',
              'group',
              'gap-1',
              'text-accent',
              'font-bold',
              'capitalizebool|',
              'text-xs',
              textStyle
            )}
          >
            <span>{text}</span>
            {icon === false ? null : icon ?? <TooltipIcon currentColor={currentColor} />}
          </div>
        )}
      </div>

      <ReactTooltip
        effect="solid"
        id={tooltipId}
        backgroundColor="#272D71"
        delayShow={150}
        delayUpdate={150}
      >
        <div
          className={classNames(
            'max-w-430px',
            'font-normal',
            'text-center',
            'normal-case',
            'whitespace-normal',
            breakAll ? 'break-all' : 'break-normal my-3'
          )}
        >
          {hoverText}
        </div>
      </ReactTooltip>
    </div>
  )
}

export default Tooltip
